.news {
  position: absolute;
  right: -8px;
  top: 0;
  width: 6px;
  height: 6px;
  border-radius: 50% 50%;

  background-color: #FF5353;
}
