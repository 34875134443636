@import '../../../styles/color.scss';

.back {
  display: flex;
  align-items: center;
  margin-right: 20px;
  div {
    display: flex;
    align-items: center;
    cursor: pointer;
    width: 28px;
    height: 28px;
    background-image: url(/imgs/pc/icon-back.svg);
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    &:hover {
      background-image: url(/imgs/pc/icon-back-active.svg);
    }
  }
  span {
    margin-left: 12px;
    font-weight: 500;
    font-size: 18px;
  }
}

.header-wrapper {
  position: relative;
  min-height: 52px;
  max-height: 52px;
  // border-bottom: 1px solid #f2f2f2;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 32px;
  padding-right: 32px;
  box-sizing: border-box;
  .icon {
    display: flex;
    align-items: center;
  }
}
.timezone {
  margin-left: 10px;
  color: #333;
  font-size: 14px;
  line-height: 22px;
  font-weight: normal;
}
.time {
  margin-left: 6px;
}
.i18n {
  margin-left: 30px;
  background-color: #fff;
  box-shadow: none;
  border: 1px solid $red;
  font-weight: 500;
  font-size: 16px;
  color: $red;
  width: 92px;
  height: 36px;
  box-sizing: border-box;
  border-radius: 20px;
  position: relative;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 2;
  .arrow {
    width: 8px;
    height: 6px;
    margin-left: 6px;
    transition: transform 0.3s;
  }
}

.language-item {
  display: flex;
  align-items: center;
  height: 40px;
  width: 144px;
  display: flex;
  border-radius: 4px;
  font-weight: 400;
  font-size: 14px;
  color: #484c70;
  padding: 0 8px;
  box-sizing: border-box;
  &-active {
    color: #ff5353;
    background: #fff5f5;
    &:hover {
      color: #ff5353 !important;
      background: #fff5f5 !important;
    }
  }
  &:hover {
    cursor: pointer;
    color: #1b1f4d;
    background: #f8f9ff;
  }
}
.modal {
  width: 560px;
  margin-top: -50px;
  border-radius: 50px;
}
.modal-body {
  padding: 30px;
}
.modal-title {
  font-size: 20px;
  font-weight: 600;
  color: #333;
}
.modal-tip {
  margin-top: 18px;
  color: #4d4d4d;
  font-size: 14px;
}
.modal-form {
  margin-top: 24px;
  display: flex;
  align-items: center;
  flex-direction: column;
}
.person {
  margin-left: 20px;
  // margin-right: 40px;
  width: 32px;
  height: 32px;
  cursor: pointer;
  position: relative;
}
.person-box {
  right: 0;
  &::before {
    display: none;
  }
}

.person-item {
  height: 40px;
  display: flex;
  align-items: center;
  cursor: pointer;
  padding: 0 8px;
  box-sizing: border-box;
  color: #484c70;
  font-weight: 400;
  font-size: 14px;

  .icon {
    width: 16px;
    height: 16px;
  }

  .title {
    display: inline-block;
    max-width: 114px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-left: 8px;
  }
  &:hover {
    background: #f8f9ff;
    border-radius: 4px;
  }
}

.input {
  position: relative;
  height: 58px;
  width: 440px;
  margin-bottom: 18px;
  background: #fbfbfb;
}

.password {
  position: absolute;
  right: 22px;
  top: 50%;
  transform: translate(0, -50%);
  img {
    width: 20px;
    height: 20px;
    cursor: pointer;
  }
}

.left-container {
  display: flex;
}

.right-container {
  display: flex;
}

.language-container {
  display: flex;
  align-items: center;
  height: 32px;
  padding: 0 12px;
  background-color: #f7f7f7;
  border-radius: 16px;
  margin-left: 20px;
  font-weight: 400;
  font-size: 14px;
  line-height: 14px;
  cursor: pointer;
  &:hover {
    background-color: #f0f1fc;
  }
  .language-icon {
    display: flex;
    margin-right: 8px;
  }
  .arrow-icon {
    display: flex;
    margin-left: 4px;
  }
}

.dropdown-container {
  top: 40px;
  border: 0.5px solid #ebecfa;
  box-shadow: 0px 2px 10px rgba(27, 31, 77, 0.15), 0px 4px 12px rgba(27, 31, 77, 0.1);
  border-radius: 8px;
  padding: 8px;
  right: 0;
  z-index: 9999;
  &.misc {
    right: 0;
  }
  &:before {
    display: none;
  }
}

.account {
  display: inline-flex;
  width: 32px;
  height: 32px;
  background-image: url('/imgs/pc/icon-account.svg');
  background-size: cover;
  background-repeat: no-repeat;
  &:hover {
    background-image: url('/imgs/pc/icon-account-hover.svg');
  }
}

.modalContent {
  width: 360px;
  height: 348px;
  display: flex;
  padding: 20px 20px 24px 20px;
  flex-direction: column;
  align-items: center;
  border-radius: 24px;
  background: linear-gradient(
      136deg,
      rgba(255, 83, 83, 0.06) -1.9%,
      rgba(109, 56, 251, 0.06) 46.02%,
      rgba(0, 157, 255, 0.06) 81.42%
    ),
    #fff;
  .modalTitle {
    color: #1b1f4d;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px;
    letter-spacing: 0.5px;
    margin: 0;
    padding: 0;
  }

  .modalSub {
    color: #484c70;
    text-align: center;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin: 0;
    padding: 0;
    margin: 12px 0 20px 0;
  }
  .continueAppointBtn {
    width: 280px;
    height: 48px;
    flex-shrink: 0;
    border-radius: 24px;
    border: none;
    pointer-events: none;
    cursor: pointer;
    margin-top: 20px;
    background: linear-gradient(90deg, #ff5353 -3.35%, #6d38fb 55.89%, #009dff 99.66%);
  }
  .qrcodeBox {
    display: flex;
    justify-content: space-around;
    width: 100%;
    .qrItem {
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      align-items: center;
      .code {
        width: 140px;
        height: 140px;
        border-radius: 12px;
        border: 1px solid #ebecfa;
        background: #fff;
      }
      span {
        font-size: 14px;
        font-weight: 400;
        text-align: center;
        margin-top: 8px;
        color: #8d8fa6;
      }
    }
  }
}

.modalSimpleContent {
  display: flex;
  width: 340px;
  padding: 24px;
  flex-direction: column;
  align-items: center;
  border-radius: 24px;
  background: linear-gradient(
      136deg,
      rgba(255, 83, 83, 0.06) -1.9%,
      rgba(109, 56, 251, 0.06) 46.02%,
      rgba(0, 157, 255, 0.06) 81.42%
    ),
    #fff;

  .modalTitle {
    color: #1b1f4d;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px;
    letter-spacing: 0.5px;
    margin: 0;
    padding: 0;
  }

  .modalSub {
    color: #484c70;
    text-align: center;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin: 0;
    padding: 0;
    margin: 12px 0 20px 0;
  }
  .continueAppointBtn {
    width: 280px;
    height: 48px;
    flex-shrink: 0;
    border-radius: 24px;
    border: none;
    pointer-events: none;
    cursor: pointer;
    background: linear-gradient(90deg, #ff5353 -3.35%, #6d38fb 55.89%, #009dff 99.66%);
  }
}
.subscribeBtn {
  width: 92px;
  height: 32px;
  text-align: center;
  line-height: 32px;
  color: #0A0A0A;
  font-size: 14px;
  font-weight: 500;
  border: 1px solid rgba(0, 0, 0, 0.06);
  border-radius: 50px;
  margin-right: 20px;
  box-sizing: border-box;
  background-color: #fff;
  cursor: pointer;
  &:hover {
    background-color: #0A0A0A;
    color: #fff;
    border: 1.5px solid #4D4D4D;
  }
}
