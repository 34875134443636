.container {
  position: relative;
  display: flex;
  align-items: center;
  height: 32px;
  &.pointer {
    cursor: pointer;
  }
  .avatar {
    position: absolute;
    top: 0;
    left: 0;
    width: 28px;
    height: 28px;
    border-radius: 50%;
    border: 2px solid #f7f7f7;
  }
  .user-info {
    display: flex;
    align-items: center;
    color: #1b1f4d;
    height: 32px;
    font-weight: 400;
    font-size: 12px;
    padding-left: 14px;
    padding-right: 4px;
    margin-left: 22px;
    background-color: #f7f7f7;
    border-radius: 0 16px 16px 0;
    &:hover {
      background-color: #F0F1FC;
;
    }
    .name {
      max-width: 96px;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      margin-right: 8px;
      font-size: 14px;
    }
    .switch-user {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 16px;
      height: 16px;
      background-color: #484c70;
      border-radius: 50%;
    }
  }
}

.wrapper {
  display: flex;
  flex-direction: column;
  padding: 8px;
  background: #ffffff;
  border: 0.5px solid #ebecfa;
  box-shadow: 0 2px 10px rgba(27, 31, 77, 0.15), 0 4px 12px rgba(27, 31, 77, 0.1);
  border-radius: 8px;
  top: 40px;
  right: 0;
  z-index: 9999;
  &:before {
    display: none;
  }
  .item {
    display: flex;
    align-items: center;
    padding: 8px;
    cursor: pointer;
    &:hover {
      background-color: #f7f8fa;
      border-radius: 4px;
    }
    &.active {
      background-color: #fff5f5;
      border-radius: 4px;
      .name {
        color: #ff5353;
      }
    }
    .avatar {
      width: 24px;
      height: 24px;
      border-radius: 50%;
    }
    .name {
      max-width: 96px;
      font-weight: 400;
      font-size: 13px;
      color: #484c70;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      margin-left: 8px;
    }
  }
}
