@import '@/styles/viewport';
.step1MainContent {
  height: auto;
  .teacherInfo {
    display: flex;
    flex-direction: row;
    border-bottom: 1px solid rgba(0, 0, 0, 0.06);
    background: #fafafa;
    .avatorBox {
      width: 60px;
      height: 60px;
      border-radius: 6px;
      overflow: hidden;
      border: 1px solid rgba(0, 0, 0, 0.06);
      .avator {
        border-radius: 6px;
        background: #FFF;
      }
    }
    .profile {
      display: flex;
      flex-direction: column;
      justify-content: center;
      flex: 1;
      .name {
        color: #0a0a0a;
        text-overflow: ellipsis;
        font-size: 24px;
        font-weight: 800;
      }
      .desc {
        color: #0a0a0a;
        text-overflow: ellipsis;
        font-size: 14px;
        font-weight: 400;
        margin-top: 8px;
      }
    }
  }
  .item {
    display: flex;
    flex-direction: row;
    border-bottom: 1px solid rgba(0, 0, 0, 0.06);
    &:last-child {
      border: none;
    }
    .iconBox {
      width: 16px;
      height: 16px;
      border-radius: 28px;
      background-color: #f5f5f5;
      padding: 6px;
      .icon {
        padding: 6px;
      }
    }
    .courseDesc {
      line-height: 28px;
      color: #0a0a0a;
      font-size: 14px;
      font-weight: 400;
    }
  }
}
