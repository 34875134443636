.wrapper {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #fff;
  z-index: 98;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  &-node {
    position: absolute;
  }
  &-index {
    z-index: -1;
  }
  .loading-container {
    width: 50px;
    height: 50px;
    .loading {
      width: 50px;
      height: 50px;
      // transform: scale(0.25);
      // transform-origin: left top;
    }
  }
  .processing-container {
    width: 80px;
    height: 30px;
    .processing {
      width: 80px;
      // transform: scale(0.25);
      // transform-origin: left top;
    }
  }
}
