.home-menu {
  display: flex;
  align-items: center;
  flex-direction: column;

  width: 176px;
  height: calc(100vh - 52px - 24px - 24px);
  margin-right: 24px;

  font-size: 14px;

  user-select: none;

  color: #484c70;
  border-radius: 12px;
  background-color: #fff;
  position: fixed;
  top: 76px;

  .logo {
    position: relative;

    width: 96px;
    height: 40px;
    margin-top: 32px;
    // &::after {
    //   content: '';
    //   position: absolute;
    //   width: 100%;
    //   bottom: -16px;
    //   left: 0;
    //   height: 1px;
    //   background-color: #fff;
    //   opacity: 0.3;
    // }
  }

  .avatar-box {
    position: relative;

    display: flex;
    align-items: center;
    flex-direction: column;

    width: 96px;
    height: auto;
    margin-top: 40px;
    padding-bottom: 10px;

    border-radius: 4px 4px 24px 24px;
    background-color: #fff;

    .star {
      padding: 14px 18px 0;
    }

    .avatar {
      width: 60px;
      height: 60px;
      margin-top: 4px;

      border-radius: 50%;
    }

    .name {
      width: calc(100% - 16px);
      margin-top: 8px;
      padding: 0 8px;

      font-size: 14px;
      font-weight: 500;

      text-align: center;
      word-wrap: break-word;

      color: #ff5353;
    }

    .change {
      display: flex;
      align-items: center;
      justify-content: center;

      box-sizing: border-box;
      min-width: 0;
      margin-top: 8px;
      padding: 6px 8px;

      font-size: 12px;

      img {
        display: block;

        width: 10px;
        height: 10px;
        margin-left: 8px;
      }
    }

    .box {
      position: absolute;
      z-index: 100;
      top: 50%;
      left: 104px;

      display: flex;
      align-items: center;
      flex-direction: column;
      justify-content: center;

      width: 222px;
      height: auto;
      padding: 10px 0;

      transform: translateY(-50%);

      border: 6px solid #ff5353;
      border-radius: 14px;
      background-color: #fff;
      box-shadow: 2px 2px 15px 0 rgba(255, 42, 42, .23);

      .students {
        position: relative;

        overflow-x: hidden;

        width: 100%;
        max-height: 300px;
      }

      &::before {
        position: absolute;
        top: 50%;
        left: -14px;

        width: 26px;
        height: 26px;

        content: '';
        transform: translateY(-50%);

        background: url('../../../../public/imgs/red-right-arrow.svg');
        background-size: 100%;
      }

      &-item {
        display: flex;
        align-items: center;

        width: 70%;
        margin: 0 auto;
        margin-bottom: 10px;

        cursor: pointer;

        &:last-child {
          margin-bottom: 0;
        }

        &-avatar {
          width: 46px;
          height: 46px;

          border-radius: 50%;
          background-repeat: no-repeat;
          background-position: center;
          background-size: 46px 46px;
        }

        span {
          display: inline-block;
          overflow: hidden;

          width: calc(100% - 46px);
          margin-left: 12px;

          font-size: 14px;
          font-weight: 500;

          white-space: nowrap;
          text-overflow: ellipsis;

          color: #666;
        }

        &-active,
        &:hover {
          img {
            box-sizing: border-box;
            padding: 2px;

            transition: padding .3s;

            border: 2px solid #ff5353;
            border-radius: 50%;
          }

          span {
            color: #ff5353;
          }
        }

        &-active {
          span {
            font-weight: bold;
          }

          &:hover {
            span {
              font-weight: bold;
            }
          }
        }
      }

      &-icon {
        position: absolute;
        top: 0;
        left: 0;

        width: 14px;
      }

      &-enter {
        opacity: 0;
      }

      &-enter-active {
        transition: opacity .3s;

        opacity: 1;
      }

      &-exit {
        opacity: 1;
      }

      &-exit-active {
        transition: opacity .3s;

        opacity: 0;
      }
    }
  }

  .menu-box {
    display: flex;
    flex-direction: column;

    width: 100%;
    margin-top: 56px;

    &-item {
      position: relative;

      display: flex;
      align-items: center;

      box-sizing: border-box;
      height: 44px;
      margin-bottom: 24px;
      padding-left: 24px;

      font-size: 13px;

      cursor: pointer;
      transition: opacity .2s ease-in;

      .menu-name {
        position: relative;

        font-size: 15px;
        line-height: 24px;

        color: rgba(72, 76, 112, .65);

        &-zh {
          font-size: 15px;
        }
      }

      .item-icon-normal {
        opacity: 1;
      }

      &:hover:not(&-active) {
        font-weight: 500;

        background-color: #f3f6ff;

        .item-icon-normal {
          visibility: hidden !important;
        }

        .item-icon-hover {
          visibility: visible !important;

          opacity: .42;
        }
      }

      &-active {
        font-weight: 500;

        color: #1b1f4d;
        background-color: #f3f6ff;

        .item-icon-normal {
          opacity: 1;
        }

        .menu-name {
          opacity: 1;
          color: #1b1f4d;
        }
      }

      .active-line {
        position: absolute;
        top: 3px;
        right: 0;

        width: 4px;
        height: 38px;

        border-radius: 4px 0 0 4px;
        background-color: #5a6ee0;
      }
    }

    .item-icon {
      position: relative;

      width: 14px;
      height: 14px;
      margin-right: 8px;

      .item-icon-normal {
        display: inline-block;
        visibility: visible;

        width: 100%;
        height: 100%;
      }

      .item-icon-hover {
        position: absolute;
        z-index: 1;
        top: 0;
        left: 0;

        display: inline-block;
        visibility: hidden;

        width: 100%;
        height: 100%;
      }
    }
  }

  .downloadAppBtn {
    position: absolute;
    bottom: 46px;

    display: flex;
    align-items: center;
    justify-content: center;

    width: 144px;
    padding: 8px 0;

    font-size: 14px;
    font-weight: 400;
    font-style: normal;
    line-height: 22px; /* 157.143% */

    color: #484c70;
    border: 1px solid #ebecfa;
    border-radius: 6px;

    text-decoration: none;
    img {
      width: 16px;
      margin-right: 4px;
    }

    .dowload-btn-en {
      margin-right: 8px;
    }

    &:hover {
      background-color: #f5f5fc;
    }

    cursor: pointer;

    .downloadLabel {
      text-align: left;
    }
  }
}
