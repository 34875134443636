.modal {
  width: 520px;
  border-radius: 50px;
  min-height: 240px;
}
.modal-close {
  right: -90px;
}
.modal-body {
  padding: 30px;
}
.modal-title {
  font-weight: 400;
  font-size: 20px;
  color: rgba(0, 0, 0, 0.8);
}
.modal-tip {
  margin-top: 18px;
  color: #4d4d4d;
  font-size: 14px;
}
.modal-form {
  margin-top: 24px;
  display: flex;
  align-items: center;
  flex-direction: column;
}
.verify-box {
  margin-top: 20px;
}

.box-item {
  display: flex;
  justify-content: space-between;
  border: 1px solid #eee;
  border-radius: 12px;
  align-items: center;
  padding: 0 20px;
  height: 84px;
  cursor: pointer;
  margin-bottom: 20px;
  span {
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    color: rgba(0, 0, 0, 0.8);
  }
  svg {
    height: 16px;
    width: 16px;
  }
}

.item-text {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  span {
    &:first-child {
      font-weight: 400;
      font-size: 16px;
      line-height: 22px;
    }
    &:last-child {
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      display: block;
      margin-top: 8px;
    }
  }
}

.sub-title {
  font-weight: 400;
  font-size: 16px;
  color: rgba(0, 0, 0, 0.4);
  display: flex;
  justify-content: center;
  span {
    color: #000;
    font-size: 20px;
  }
}

.account-text {
  color: #000;
  margin-top: 8px;
  font-size: 20px;
}

.input {
  position: relative;
  height: 54px;
  width: 100%;
  margin-top: 18px;
  border-radius: 12px;
  background: #f9f9f9;
  border: none;
  overflow: visible;
  input {
    font-size: 16px;
    &::placeholder {
      color: rgba(0, 0, 0, 0.2);
      font-weight: 400;
      font-size: 16px;
      line-height: 22px;
    }
  }
}

.submit,
.finished {
  width: 264px;
  height: 54px;
  margin-top: 38px;
  border-radius: 38px;
  font-size: 16px;
  box-shadow: 0px 4px 0px #e13e3e;
  transition: all 0.1 ease-out;
  -webkit-appearance: none;
  &:hover {
    transform: scale(1);
    &:active {
      box-shadow: none;
      transform: translateY(2px);
    }
  }
}

.finished {
  margin-top: 40px;
}

.icon {
  position: absolute;
  top: 18px;
  right: 18px;
  cursor: pointer;
}
