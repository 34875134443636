
.wkButton {
  width: 80px;
  height: 26px;
  box-sizing: border-box;
  border: 1px solid #DFE0ED;
  background: #ffffff;
  font-family: Roboto;
  font-size: 14px;
  font-weight: 400;
  text-align: center;
  display: flex;
  align-items: center;
  padding: 0 26px;
  color: #484C70;
  justify-content: center;
  border-radius: 15px;
  gap: 8px;

  &.warn {
    
  }
  
  &.primary {
    background: #FF5353;
    border: none;
    color: #ffffff;
  }
  
  &.err {
    
  }
  
  &.disabled {
    opacity: .7;
  }
}