.step3MainContent {
  width: 100%;
  .reasonItem {
    padding: 16px 24px;
    border-radius: 10px;
    border: 1.5px solid rgba(0, 0, 0, 0.06);
    background: #fff;
    margin-bottom: 16px;
    cursor: pointer;
    &:last-child {
      margin-bottom: 0;
    }
  }

  .active {
    border-radius: 8px;
    border: 1.5px solid #cd292a;
    background: linear-gradient(0deg, rgba(205, 41, 42, 0.02) 0%, rgba(205, 41, 42, 0.02) 100%), #fff;
    position: relative;
    .activeIcon {
      border-radius: 0 0 6px 0;
      position: absolute;
      right: -1px;
      bottom: -1px;
      width: 20px;
      height: 20px;
      .checked {
        border-radius: 0 0 6px 0;
        width: 20px;
        height: 20px;
      }
    }
  }
}
