@import '~@/styles/color.scss';
@import '~@/styles/scrollbar.scss';

.profile-info {
  margin-bottom: 20px;
  padding: 20px;
  position: relative;
  display: flex;
  align-items: center;
  box-sizing: border-box;
  border: 1px solid transparent;
  justify-content: space-between;

  &:nth-last-child(1) {
    margin: 0;
  }

  &:hover {
    cursor: pointer;
    border-color: #f7f7f7;
    box-shadow: 0 2px 13px rgba(209, 224, 233, 0.5);
    border-radius: 26px;
    background: #f9f9f9;
    transition: all 0.2s;
  }

  .left {
    display: flex;
    gap: 32px;

    .avatarWrapper {
      width: 80px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      position: relative;

      .avatar {
        position: relative;
        border-radius: 50%;
        width: 60px;
        height: 60px;
        overflow: hidden;

        .avatarHover {
          width: 100%;
          height: 100%;
          position: absolute;
          top: 0;
          left: 0;
          display: flex;
          justify-content: center;
          align-items: center;
          z-index: 9;
        }
      }

      .currentStu {
        box-sizing: border-box;
        min-width: 60px;
        padding: 0 5px;
        height: 20px;
        margin-top: 8px;
        background: #ffeeee;
        border-radius: 12px;
        line-height: 20px;
        font-size: 11px;
        color: #ff5353;
        text-align: center;

        &.en {
          font-size: 10px;
        }
      }
    }

    .infoBlock {
      display: flex;
      width: 332px;
      gap: 12px;

      .item {
        height: 100%;
        display: flex;
        flex-direction: column;
        gap: 12px;
        justify-content: space-around;

        &:nth-last-child(1) {
          flex: 1;
          min-width: 0;
        }

        &-title {
          font-size: 14px;
          line-height: 25px;
          color: #999;
          min-width: 70px;
          text-align: left;
          white-space: nowrap;
        }

        &-value {
          white-space: nowrap;
          text-overflow: ellipsis;
          font-size: 15px;
          color: #333;
          overflow: hidden;
          min-width: 0;
          line-height: 25px;

          &.isEdit {
            overflow: initial;
          }
        }

        .itemInput {
          height: 36px;
          background: #ffffff;
          border-radius: 4px;
          border: 1px #f0f1fc solid;
          line-height: 36px;
          box-sizing: border-box;
        }

        .select {
          background: #ffffff;

          > div {
            border: 1px solid #f0f1fc;

            &:focus,
            &:hover,
            &:active {
              border: 1px solid #f0f1fc;
              &:last-child {
                span {
                  display: none;
                }
              }
            }
            &:last-child {
              span {
                display: none;
              }
            }
          }
        }
      }
    }
  }
}

.edit {
  position: absolute;
  width: 48px;
  right: 20px;
  display: none;
}

.profile-info {
  &:hover {
    .edit {
      display: block;
    }
  }
}

.operate {
  display: flex;
  flex-direction: column;
  z-index: 2;
  box-sizing: border-box;
  position: absolute;
  right: 20px;
  justify-content: space-between;
  top: 50%;
  transform: translateY(-50%);

  button {
    padding: 0;
    height: 26px;
    width: 80px;
    margin-bottom: 16px;
    &:last-child {
      margin-bottom: 0;
    }
  }
}

.profile-info-edit {
  box-shadow: 0 2px 13px rgba(209, 224, 233, 0.5);
  border-radius: 26px;
  background: #f9f9f9;
  animation: fadeIn 0.2s ease-in;

  .avatar {
    &::after {
      position: absolute;
      content: '';
      top: 0;
      left: 50%;
      transform: translateX(-50%);
      width: 100%;
      height: 100%;
      background: rgba(0, 0, 0, 0.5);
      border-radius: 50%;
      animation: fadeIn 0.2s linear;
      backdrop-filter: blur(1px);
    }
  }
}

.file {
  display: none;
}

.modal {
  padding: 30px;
  border-radius: 50px;
}

.modal-title {
  position: relative;
  font-weight: 600;
  font-size: 24px;
  line-height: 18px;
  color: #333333;
  display: block;
}

.modal-body {
  display: flex;
  margin-top: 40px;
}

.mask {
  opacity: 0.1;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #ececec;
  border-radius: 78px;
}

.modal-operate {
  display: flex;
  margin-top: 30px;
  justify-content: center;
  button {
    width: 180px;
    height: 52px;
    margin-right: 30px;
  }
}

.upload {
  width: 520px;
  height: 360px;
  border-radius: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  box-shadow: inset 0 0 2px rgba(16, 183, 255, 0.6);
  cursor: pointer;
  svg {
    margin-bottom: 18px;
  }
}

.preview {
  flex: 1;
  display: flex;
  align-items: center;
  flex-direction: column;
  &-avatar {
    opacity: 0;
    width: 120px;
    height: 120px;
    border-radius: 50%;
    overflow: hidden;
    &-show {
      opacity: 1;
    }
  }
  span {
    margin-top: 16px;
  }
}

.cropper {
  width: 520px;
  height: 360px;
  border-radius: 12px;
  overflow: hidden;
  box-shadow: inset 0 0 2px rgba(16, 183, 255, 0.6);
}

@keyframes fadeIn {
  from {
    opacity: 0.4;
  }
  to {
    opacity: 1;
  }
}

.studentListContainer {
  padding-bottom: 100px;
  margin-top: 24px;
  flex: 1;
  position: relative;
  .title {
    font-weight: 600;
    font-size: 18px;
    line-height: 31px;
    display: inline-block;
    &::before {
      margin-right: 10px;
      position: relative;
      height: 16px;
      padding: 0 4px;
      content: '';
      background-color: $red;
      border-radius: 4px;
    }
  }
  .students {
    margin-top: 24px;
    flex: 1;
    position: relative;
  }
}
