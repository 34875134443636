
.wkSelect {
  position: relative;
  width: 100%;
  
  &-label {
    box-sizing: border-box;
    align-items: center;
    height: 36px;
    line-height: 36px;
    border: 1px solid #F0F1FC;
    background: #ffffff;
    padding: 0 32px 0 10px;
    font-size: 15px;
    font-weight: 400;
    color: #333333;
    border-radius: 4px;
    
    
    >p {
      flex: 1;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      min-width: 0;
    }
    
    .noValue {
      color: #8D8FA6;
    }
  }
  
  
  &-dropdown {
    position: absolute;
    left: 0;
    top: 40px;
    z-index: 1;
    height: 0;
    padding: 8px;
    border-radius: 8px;
    box-shadow: 0 2px 10px 0 #1B1F4D26;
    display: flex;
    flex-direction: column;
    gap: 8px;
    box-sizing: border-box;
    background: #ffffff;
    transform: scaleY(0);
    transform-origin: top left;
    transition: all .3s cubic-bezier(.32,.94,.6,1) 0s;
    overflow: hidden;
    min-width: 250px;
    max-width: 350px;
    
    &.open {
      transform: scaleY(1);
      transform-origin: top;
      height: auto;
      max-height: 260px;
      transition: all .3s cubic-bezier(.32,.94,.6,1) 0s;
    }

    &-search {
      border: 1px solid #DFE0ED;
      border-radius: 4px;
      display: flex;
      align-items: center;
      padding: 0 8px;
      box-sizing: border-box;
      gap: 8px;
      
      &-input {
        display: block;
        height: 32px;
        flex: 1;
        outline: none;
        border: none;
        font-size: 15px;
        line-height: 22px;
        font-weight: 400;
        width: 100%;
      }
      
    }
    
    &-list {
      flex: 1;
      list-style: none;
      overflow-y: scroll;
      width: 100%;
      font-size: 15px;
      line-height: 22px;
      font-weight: 400;
      box-sizing: border-box;
      
      >li {
        box-sizing: border-box;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        padding: 0 8px;
        min-width: 0;
        height: 40px;
        line-height: 40px;
        color: #484C70;
        
        &:hover {
          background: #F8F9FF;
          color: #1B1F4D;
        }

        &.act {
          background: #F8F9FF;
          font-weight: 500;
          color: #1B1F4D;
        }
      }
    }
    
  }
}