html:lang(zh) .cancelCourseContainer *,
body:lang(zh) .cancelCourseContainer * {
  font-family: 'MiSans', 'PingFang SC', sans-serif !important;
}
html:lang(en) .cancelCourseContainer *,
body:lang(en) .cancelCourseContainer * {
  font-family: 'Figtree', 'PingFang SC', sans-serif !important;
}
.cancelCourseContainer {
  background-color: #f4f4ef;
  min-height: 100%;
  display: flex;
  justify-content: center;
}
.centerContent {
  width: 591px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 24px;
  border-radius: 8px;
  height: auto;
  justify-content: center;
  padding: 32px;
  box-sizing: border-box;
  .confirmInfo {
    background: #fff;
    padding: 32px 24px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    border-radius: 12px;
    width: 100%;
    .title {
      color: #0a0a0a;
      text-align: center;
      font-size: 20px;
      font-weight: 700;
    }
    .preTitle {
      color: #4d4d4d;
      text-align: center;
      text-overflow: ellipsis;
      font-size: 14px;
      font-weight: 400;
      margin-top: 8px;
      margin-bottom: 32px;
    }
  }
  .footerBtns {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 0 24px;
    width: calc(100%);
    .btn {
      width: 248px;
      height: 51px;
      gap: 24;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 200px;
      background: #fff;
      cursor: pointer;
    }
    .colorfulBtn {
      background-color: #cd292a;
      color: #fff;
    }
    .whiteBtn {
      background-color: #fff;
    }
  }
}
