@import '~@/styles/color.scss';
@import '~@/styles/scrollbar.scss';
@import '~@/styles/config.scss';

.home-wrapper {
  width: 100%;
  height: 100vh;
  background-color: $red;
  display: flex;
  &.center-mode {
    flex-direction: column;
    background: none;
    justify-content: center;
  }
}
.container {
  display: flex;
  flex-direction: column;
  flex: 1;
  // border-radius: 60px 0 0 60px;
  background-color: #fff;
  width: calc(100vw - 124px);
  overflow-y: scroll;
  overflow-x: auto;
  &.center-mode {
    width: 100vw;
    height: 100%;
    background: $bgWrpper;
    flex-direction: row;
    align-items: center;
  }
  &-full {
    border-radius: 0;
  }
}

.center-mode-container {
  display: flex;
  min-width: 1200px;
  margin: auto;
}

.wrapper {
  position: relative;
  // height: calc(100% - 70px);
  flex: 1;
  &.center-mode {
    width: 1000px;
    height: 94vh;
    flex: none;
    border-radius: 12px;
    margin-left: calc(176px + 28px);
  }
  // .inner-wraper {
  //   @include scrollBar;
  //   margin: 24px 4px;
  //   overflow-x: hidden;
  //   overflow-y: auto;
  //   height: calc(100vh - 52px - 24px * 4);
  // }
}

.loading {
  border-bottom-left-radius: 60px;
}
